.box {
  border: 1px solid black;
  margin: 2px;
  padding: 3px 10px 3px 10px;
  background-repeat: no-repeat;
  background-position: 10px center;
  border-radius: 5px;
}

.itemPaddingBottom {
  padding-bottom: 0.5em;
}

.itemPaddingTop {
  padding-top: 0.5em;
}

.itemPadding {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.indentLeft {
  padding-left: 2em;
}

ol {
  margin: 0;
}