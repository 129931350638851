.key {
    font-family: inherit;
    font-weight: bold;
    font-size: 20px;
    padding: 0;
    height: 50px;
    min-width: 38px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    background-color: grey;
    color: white;
    text-transform: uppercase;
    border-color: black;
}

.validGrid {
    border: 0;
}

.errorGrid {
    border: 0.5px !important;
    border-color: red !important;
    border-style: solid !important;
}