.undoButton {
    padding: 0 !important;
    margin-left: 10px !important;
}

.restartButton {
    padding: 0 !important;
    margin-right: 10px !important;
}

.blink {
    animation-name: blink;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
    animation-timing-function: linear;
}

@keyframes blink {
    0%   {opacity: 1;}
    40%  {opacity: 1;}
    50%  {opacity: 0.25;}
    60%  {opacity: 1;}
    100%  {opacity: 1;}
  }